import { useMount } from 'ahooks'
import { t } from '@lingui/macro'
import { RegexpList } from '@/helper/exp'

const emailExp = /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/
const cnExp = /[\u4e00-\u9fa5]/g
const ValidateType = {
  containUppercaseAndLowercaseLetters: /(?=.*?[A-Z])(?=.*?[a-z])/,
  containsNumbers: /(?=.*?[0-9])/,
  // containsSpecialsymbols: /(?=.*?[#?!@$%^&*()=+-.])/,
  passwordLength: /^.{8,16}$/,
  containsWhitespace: /(?=.*[\s])/,
}

enum TabOption {
  Email = 'email',
  Phone = 'phone',
}

enum TabOptionRequest {
  Email = 2,
  Phone = 1,
}

enum CodeType {
  // 注册
  register = 1,
  // 修改密码
  ModifyPassword = 4,
}

const emailValidate = () => {
  return {
    validator: (value: string | undefined, cb) => {
      if (!value) return cb(t`user.validate_form_02`)
      if (cnExp.test(value)) return cb(t`features_user_utils_validate_2556`)
      if (value && !emailExp.test(value)) {
        return cb(t`features_user_utils_validate_2556`)
      }
      return cb()
    },
  }
}

const phoneValidate = () => {
  return {
    validator: (value: Record<'phone' | 'areacode', string> | undefined, cb) => {
      if (!value?.phone || !value?.areacode)
        return cb(t`features_user_initial_person_areacode_form_index_stlfdxvj14bv_vwy5qv2g`)
      return cb()
    },
  }
}

const passwordValidateAll = value => {
  return Object.keys(ValidateType).every(item => {
    if (['containsWhitespace'].includes(item)) {
      return !ValidateType[item].test(value)
    } else {
      return ValidateType[item].test(value)
    }
  })
}

const passwordValidate = () => {
  return {
    validator: (value: string | undefined, cb) => {
      if (!value) return cb(t`features_user_operate_user_operate_ar8l8up0kk`)
      if (!ValidateType.passwordLength.test(value)) return cb(t`features_user_operate_user_operate_9on79ywvl1`)
      if (!ValidateType.containUppercaseAndLowercaseLetters.test(value))
        return cb(t`features_user_operate_user_operate_spjhfjnk4z`)
      if (!ValidateType.containsNumbers.test(value)) return cb(t`features_user_operate_user_operate_rtaodgxvf4`)
      // if (ValidateType.containsSpecialsymbols.test(value)) return cb('不能有特殊字符')
      if (ValidateType.containsWhitespace.test(value)) return cb(t`features_user_operate_user_operate_5glolzpq89`)
      return cb()
    },
  }
}

const confirmPasswordValidate = (password: string) => {
  return {
    validator: (value: string | undefined, cb) => {
      if (!value) return cb(t`features_user_operate_user_operate_fwtlvjr81h`)
      if (value && value !== password) {
        return cb(t`features_user_operate_user_operate_fwtlvjr81h`)
      }

      return cb()
    },
  }
}

const verificationCode = (senVerificationCode?: boolean) => {
  return {
    required: true,
    validator: (value: string | undefined, cb) => {
      if (!value) return cb(t`features_user_utils_validate_5101195`)
      if (!senVerificationCode) return cb(t`features_user_utils_validate_5101213`)
      if (!/^\d{6}$/.test(value)) {
        return cb(t`features_user_utils_validate_2697`)
      }

      return cb()
    },
  }
}

function UserInformationDesensitization(str: string): string {
  if (str === '' || str === undefined || str === null) return ''

  const regExp = /@/g
  const numberExp = /^[\d]+$/
  const isEmail = str.match(regExp)
  const isPhone = str.match(numberExp)

  if (isEmail) {
    const email = str.split('@')
    const emailExp = email[0].length < 3 ? /(?:.{1})[^@]+(?=@)/ : /(?:.{2})[^@]+(?=.{2}@)/
    return str.replace(emailExp, '****')
  }

  if (isPhone) {
    const phoneExp = /(\d{3})\d*(\d{4})/
    return str.replace(phoneExp, '$1****$2')
  }

  return ''
}

const useGetAreacode = form => {
  useMount(() => {
    form.setFieldsValue({ phone: { areacode: '86' } })
  })
}

const removeEmojis = inputString => {
  // 使用正则表达式替换表情符号为空字符串
  const cleanString = inputString?.replace(RegexpList.emoiExp, '')

  return cleanString
}

export {
  emailValidate,
  CodeType,
  emailExp,
  TabOption,
  TabOptionRequest,
  passwordValidate,
  cnExp,
  confirmPasswordValidate,
  passwordValidateAll,
  phoneValidate,
  verificationCode,
  UserInformationDesensitization,
  useGetAreacode,
  removeEmojis,
}
